<template>
  <section>
    <div class="event_header"></div>
    <div class="event_container">
      <h1 class="mb-10">{{ eventName }}</h1>
      <div class="event_content" v-for="(item, mainindex) in eventPlans" :key="mainindex">
        <div class="event_package">
          <div>
            <h5>{{ item.name }}</h5>
            <div class="tw-flex my-2">
              <!-- <div
                v-for="(image, index) in item.images"
                :key="index"
                class="mr-2"
              > -->
                <!-- <img :src="image" alt="" /> -->
                <img class="mr-2" v-if="mainindex >= 0" src="@/assets/landingPage/events/event_wristband.svg" alt="">
                <img class="mr-2" v-if="mainindex >= 1" src="@/assets/landingPage/events/event_shirt.svg" alt="">
                <img class="mr-2" v-if="mainindex >= 2" src="@/assets/landingPage/events/event_hoodie.svg" alt="">


              <!-- </div> -->
            </div>
            <div class="tw-flex tw-items-center" >
              <div v-for="(product, index) in item.products" :key="index">
                <span >{{ product.name}} </span>

                 <span class="mr-1" v-if="product.name !== 'Hoodie'">,</span>
                 <!-- <span v-if="mainindex === 1 && product.name === 'T-shirt'"></span> -->

              </div>

            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-items-end">
            <h4 class="tw-mb-4">NGN{{ processAmount(item.amount) }}</h4>

            <v-btn
            @click="select(item)"
             :color="selected && selected.name === item.name ?'#00050D':'#FFFFFF'"
             :class="[ selected && selected.name === item.name ?'active_button':'button' ]"
              rounded depressed>
              {{selected && selected.name === item.name ?'Selected':'Select'}}</v-btn>

          </div>


        </div>
      </div>
      <div class="event_content tw-w-full tw-mt-6 tw-flex tw-items-center tw-justify-between">
        <v-btn v-if="!getUserEmail" color="primary" class="pay-button" @click="payForItem">PAY</v-btn>
        <v-btn :disabled="!selected.id" large v-else color="primary" class="pay-button" depressed>
          <paystack
            :email="getUserEmail"
            :amount="selected.amount*100"
            :reference="'Event'+reference"
            :paystackkey="key"
            :callback="callback"
            :close="close"
          >
            PAY
          </paystack>
        </v-btn>
        <div> <img src="../assets/landingPage/events/paystack_tag.svg" alt=""></div>
      </div>
    </div>
  </section>
</template>

<script>
import isUserLoggedIn from "@/mixins/isUserLoggedIn";
import {getEventDetail} from "@/services/auth-services";
import { verifyEventPayment } from "@/services/auth-services";
import paystack from 'vue-paystack';

export default {
  name: "Event",
  components:{paystack},
  mixins: [isUserLoggedIn],
  data() {
    return {
      selected: {},
      selectedId:0,
      eventPlans:[],
      packages: [
        {
          name: "Basic",
          amount: "5,000.00",
          description: "Paper wrist-bands",
          images: [require("@/assets/landingPage/events/event_wristband.svg")],
        },
        {
          name: "Gold",
          amount: "10,000.00",
          description: "Paper wrist-band, T-shirt",
          images: [
            require("@/assets/landingPage/events/event_wristband.svg"),
            require("@/assets/landingPage/events/event_shirt.svg"),
          ],
        },
        {
          name: "Premium",
          amount: "30,000.00",
          description: "Paper wrist-band, T-shirt, Hoodie",
          images: [
            require("@/assets/landingPage/events/event_wristband.svg"),
            require("@/assets/landingPage/events/event_shirt.svg"),
            require("@/assets/landingPage/events/event_hoodie.svg"),
          ],
        },
      ],
      key:process.env.VUE_APP_PAY_STACK_KEY,
      dialog:false,
      loading:false,
      eventName: ''
    };
  },
  beforeRouteEnter (to, from, next) {
    localStorage.setItem('routeLocation',to.name)
   next()
  },
  computed:{
    getUserEmail() {
      const user = JSON.parse(localStorage.getItem("userData"));
      if (user) return user.email

    },
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      // this.flutterRef += possible.charAt(Math.floor(Math.random() * possible.length)+this.getUserEmail.charAt(0))
      if(this.getUserEmail)
      return text+this.getUserEmail.charAt(0);
    },

  },
  created(){
    const code = this.$route.query.code
      getEventDetail(code).then(
        res => {
          this.eventName = res.data.name
          this.eventPlans = res.data.plans
        },
        error => {
          console.log(error)
        }
      );
  },
  methods:{
    select(plan){
        this.selected = plan
    },
    processAmount(amount) {
      if (!amount) return;
      let kobo = "00";
      let naira = amount;
      return String(naira).replace(/(.)(?=(\d{3})+$)/g, "$1,") + "." + kobo;
    },
    payForItem(){
     if(this.getLogInStatus){
       // const selectedItem = this.eventPlans.find(element => element.id === this.selectedId);
       //  console.log(selectedItem)
     }else{
       this.$router.push({ name: 'Login' ,query:{redirect:'Event'}})
     }

    },
      close: () => {
        console.log("You closed checkout page")
      },
      callback: async function (response) {
      let _this = this
      if(_this.getUserEmail){
        console.log(response)
        this.dialog = false
        this.loading = true
        await verifyEventPayment({
          reference: response.reference,
          plan:{id: _this.selected.id},
        }).then(res => {
          console.log(res)
          if(res.data.detail === 'success'){
            this.loading = false
            this.$router.push({name:'PaymentReceivedPage'})
          }
          else{
            this.loading = false
            this.dialog=true


          }
        })
          .catch(err => {
            this.loading = false
            this.$displaySnackbar({success: false, message: err.response.data.detail})

            this.dialog=true

          })
        }
      else {
        await this.$router.push({ name: 'Login', query: { redirect: 'Event' } })

      }
      },



  }
};
</script>

<style lang="scss" scoped>
.event_header {
  background: url("../assets/landingPage/events/event_banner.svg") repeat center;
  height: 190px;
  background-size: cover;
}
.event_container {
  width: 60%;
  background: #ffffff;
  border: 1px solid #fafafa;
  box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08),
    0px 2px 4px rgba(173, 173, 173, 0.04);
  border-radius: 20px;
  max-width: 1200px;
  margin: -100px auto 150px;
  padding: 50px;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  h1 {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: -0.04em;

    color: #514a41;
  }
  @media (max-width: 1000px) {
    width: 80%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
}
.event_content {
  width: 80%;
  @media (max-width: 1000px) {
    width: 100%;
  }
}
.event_package {
  margin: 10px 0;
  padding: 0 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #f4f3f3;
  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #514a41;
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #9a9595;
  }
  h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #514a41;
  }
}
.pay-button{
  border-radius: 100px;
}

.button{
font-size: 14px;
line-height: 18px;
color: #FF7A01;
text-transform: capitalize;
border: 1px solid #FF7A01 !important;
letter-spacing: 0;

}
.active_button{
font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #FFFFFF;
text-transform: capitalize;
letter-spacing: 0;

}
</style>

